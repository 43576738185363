<template>
    <div class="banner_ad">
      <img src="https://picsum.photos/id/23/200/300" alt="横幅广告" @click="test">
      <el-tag class="ml-2 ad_tag danger" color="#cc2b33">广告1</el-tag>
    </div>
</template>

<script>
export default {
  name: "BannerAds",
  beforeCreate() {
    // 设置CSS样式
    document.querySelector("body").setAttribute("style", "margin: 0;padding: 0;")
  },
  methods: {
    test(){
      console.log("这里");
    }
  }
}
</script>

<style scoped>

.banner_ad {
  width: 100%;
  height: 120px;
  position: relative;
}

.banner_ad > img{
  width: 100%;
  height: 100%;
}

.banner_ad > .ad_tag{
  position: absolute;
  bottom: 0;
  right: 0;
  color: #FFFFFF;
  border: 0;
}
</style>